import React, { useState, useContext, ChangeEvent, FC } from "react"
import * as z from "zod"
import IcfPasswordRequirements from "./IcfPasswordRequirement"
import { RegisterFormContext } from "../../components/auth/register-form/context"
import { validateFirstAndLastName } from "../../components/auth/register-form/name-form"
import { UserExists } from "../../api/auth.service"
import { Button } from "../../components/ui/button"
import Recaptcha from "../../components/auth/register-form/Recaptcha"
import {
  SvgCapterra,
  SvgEye,
  SvgEyeOff,
  SvgStarRating,
  SvgWarning,
} from "../../components/icons"

interface Props {
  setConfModal: (statement: boolean) => void
}

const IcfRegistrationForm: FC<Props> = ({ setConfModal }) => {
  const {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    createAccount,
  } = useContext(RegisterFormContext)

  const [loading, setLoading] = useState(false)
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const [confUserPassword, setConfUserPassword] = useState<string>("")
  const [stateType, setStateType] = useState<"password" | "text">("password")
  const [stateConfType, setStateConfType] = useState<"password" | "text">(
    "password"
  )
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confUserPassword: "",
  })
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateForm()) {
      setLoading(true)

      const emailSchema = z.string().email().safeParse(email)

      if (!emailSchema.success) {
        setErrors((prev) => ({
          ...prev,
          email: "Please add a valid email address",
        }))
        setLoading(false)
        return
      } else {
        setErrors((prev) => ({ ...prev, email: "" }))
      }

      const emailExists = await doesEmailExist(email)
      if (emailExists?.found) {
        setErrors((prev) => ({ ...prev, email: "Email already in use" }))
        setLoading(false)
        return
      }

      if (firstName.trim() === "") {
        setErrors((prev) => ({ ...prev, firstName: "Required" }))
        setLoading(false)
      } else {
        setErrors((prev) => ({ ...prev, firstName: "" }))
      }

      if (lastName.trim() === "") {
        setErrors((prev) => ({ ...prev, lastName: "Required" }))
        setLoading(false)
      } else {
        setErrors((prev) => ({ ...prev, lastName: "" }))
      }

      if (password.trim() === "") {
        setErrors((prev) => ({ ...prev, password: "Required" }))
        setLoading(false)
      } else {
        setErrors((prev) => ({ ...prev, password: "" }))
      }

      if (confUserPassword.trim() === "") {
        setErrors((prev) => ({ ...prev, confUserPassword: "Required" }))
        setLoading(false)
      } else {
        setErrors((prev) => ({ ...prev, confUserPassword: "" }))
      }

      if (
        firstName.trim() !== "" &&
        lastName.trim() !== "" &&
        password.trim() !== "" &&
        confUserPassword.trim() !== ""
      ) {
        try {
          await createAccount("ICF")  
          setConfModal(true)
        } catch (error) {
          console.error("Error creating account:", error)
          setErrors((prev) => ({
            ...prev,
            password: "Failed to create account. Please try again.",
          }))
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }

  const doesEmailExist = async (email: string) => {
    try {
      const data = await UserExists.verifyUserExists({
        attributes_to_verify: {
          email,
        },
      })

      return { found: data.user_found }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const invalidNameMsg = "Please enter a valid name"

    if (inputName === "email") {
      setEmail(inputValue)
      setErrors((prev) => ({ ...prev, email: "" }))
    }

    if (inputName === "first") {
      setFirstName(inputValue)
      setErrors((prev) => ({
        ...prev,
        firstName: inputSuccess ? "" : invalidNameMsg,
      }))
    }

    if (inputName === "last") {
      setLastName(inputValue)
      setErrors((prev) => ({
        ...prev,
        lastName: inputSuccess ? "" : invalidNameMsg,
      }))
    }
  }

  const validateForm = () => {
    const newErrors = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confUserPassword: "",
    }

    const errorMsg = "Required"
    let valid = true

    const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/

    const fields = [
      {
        field: "email",
        value: email,
        customError:
          !email.match(mailformat) && "Please add a valid email address",
      },
      { field: "firstName", value: firstName },
      { field: "lastName", value: lastName },
      { field: "password", value: password },
      { field: "confUserPassword", value: confUserPassword },
    ]

    fields.forEach(({ field, value, customError }) => {
      if (!value) {
        newErrors[field as keyof typeof newErrors] = errorMsg
        valid = false
      } else if (customError) {
        newErrors[field as keyof typeof newErrors] = customError as string
        valid = false
      }
    })

    setErrors(newErrors)
    return valid
  }

  const inputClasses = `border-grayCloud focus:border-blurple hover:bg-grayFlash hover:border-grayFlash relative flex h-14 cursor-text flex-col overflow-hidden rounded-md transition-colors pl-4 w-full border-2 focus:outline-none`

  return (
    <form
      onSubmit={handleSubmit}
      className="p-4 sm:p-8"
      style={{
        minWidth: "100%",
        maxWidth: "650px",
      }}
    >
      <div className="relative w-full max-h-full overflow-y-auto rounded-xl bg-white p-6 sm:p-12 h-[725px]">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex flex-col w-full mb-[24px] sm:w-1/2">
            <label htmlFor="first-name" className="mb-2">
              First Name
            </label>
            <input
              id="first-name"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => handleChange(e, "first")}
              disabled={loading}
              className={`${inputClasses} ${
                errors.firstName
                  ? "border-vividRed hover:border-vividRed focus:border-vividRed"
                  : ""
              }`}
              style={{
                backgroundColor: errors.firstName
                  ? "rgba(255, 0, 0, 0.05)"
                  : "",
              }}
            />
            {errors.firstName && (
              <div
                style={{ color: "red" }}
                className="flex items-center text-red-500 text-sm"
              >
                <SvgWarning className="w-[18px] h-[18px] mr-1" />{" "}
                {errors.firstName}
              </div>
            )}
          </div>
          <div className="flex flex-col w-full sm:w-1/2">
            <label htmlFor="last-name" className="mb-2">
              Last Name
            </label>
            <input
              id="last-name"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => handleChange(e, "last")}
              disabled={loading}
              className={`${inputClasses} ${
                errors.lastName
                  ? "border-vividRed hover:border-vividRed focus:border-vividRed"
                  : ""
              }`}
              style={{
                backgroundColor: errors.lastName ? "rgba(255, 0, 0, 0.05)" : "",
              }}
            />
            {errors.lastName && (
              <div
                style={{ color: "red" }}
                className="flex items-center text-red-500 text-sm"
              >
                <SvgWarning className="w-[18px] h-[18px] mr-1" />{" "}
                {errors.lastName}
              </div>
            )}
          </div>
        </div>
        <label htmlFor="email" className="mt-2 block">
          Email
        </label>
        <div className="mb-[24px] ">
          <input
            id="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            autoFocus
            className={`${inputClasses} ${
              errors.email
                ? "border-vividRed hover:border-vividRed focus:border-vividRed"
                : ""
            }`}
            style={{
              backgroundColor: errors.email ? "rgba(255, 0, 0, 0.05)" : "",
            }}
          />
          {errors.email && (
            <div
              style={{ color: "red" }}
              className="flex items-center text-red-500 text-sm"
            >
              <SvgWarning className="w-[18px] h-[18px] mr-1" /> {errors.email}
            </div>
          )}
        </div>
        <label htmlFor="password" className="mb-2 mt-2 block">
          Password
        </label>
        <div className="relative mb-[20px]">
          <input
            id="password"
            placeholder="Create your password"
            value={password}
            onFocus={() => setShowPassReq(true)}
            onBlur={(e) => {
              if (
                !confUserPassword &&
                !e.relatedTarget?.classList.contains("toggle-button")
              ) {
                setShowPassReq(false)
              }
            }}
            onChange={(e) => setPassword(e.target.value)}
            type={stateType}
            autoComplete="off"
            disabled={loading}
            className={`${inputClasses} ${
              errors.password
                ? "border-vividRed hover:border-vividRed focus:border-vividRed"
                : ""
            }`}
            style={{
              backgroundColor: errors.password ? "rgba(255, 0, 0, 0.05)" : "",
            }}
          />
          {password && (
            <Button
              size="icon"
              variant="ghost"
              type="button"
              className="absolute right-2 top-1/2 -translate-y-1/2 toggle-button"
              onClick={(e) => {
                e.stopPropagation()
                if (stateType === "password") {
                  setStateType("text")
                } else {
                  setStateType("password")
                }
              }}
            >
              {stateType === "password" ? <SvgEye /> : <SvgEyeOff />}
            </Button>
          )}
          {errors.password && (
            <div
              style={{ color: "red" }}
              className="flex items-center text-red-500 text-sm absolute"
            >
              <SvgWarning className="w-[18px] h-[18px] mr-1" />
              {errors.password}
            </div>
          )}
        </div>{" "}
        <div className="relative mb-6">
          <input
            id="confirm-password"
            placeholder="Re-enter your password"
            value={confUserPassword}
            onFocus={() => setShowPassReq(true)}
            onBlur={(e) => {
              if (
                !password &&
                !e.relatedTarget?.classList.contains("toggle-button")
              ) {
                setShowPassReq(false)
              }
            }}
            onChange={(e) => setConfUserPassword(e.target.value)}
            type={stateConfType}
            autoComplete="off"
            disabled={loading}
            className={`${inputClasses} ${
              errors.confUserPassword
                ? "border-vividRed hover:border-vividRed focus:border-vividRed"
                : ""
            }`}
            style={{
              backgroundColor: errors.confUserPassword
                ? "rgba(255, 0, 0, 0.05)"
                : "",
            }}
          />
          {confUserPassword && (
            <Button
              size="icon"
              variant="ghost"
              type="button"
              className="absolute right-2 top-1/2 -translate-y-1/2 toggle-button"
              onClick={(e) => {
                e.stopPropagation()
                if (stateConfType === "password") {
                  setStateConfType("text")
                } else {
                  setStateConfType("password")
                }
              }}
            >
              {stateConfType === "password" ? <SvgEye /> : <SvgEyeOff />}
            </Button>
          )}
          {errors.confUserPassword && (
            <div
              style={{ color: "red" }}
              className="flex items-center text-red-500 text-sm absolute"
            >
              <SvgWarning className="w-[18px] h-[18px] mr-1" />
              {errors.confUserPassword}
            </div>
          )}
        </div>
        
        {showPaswReqs && (
          <div className="-mt-4">
            <IcfPasswordRequirements
              validEntree={password}
              confirmPassword={confUserPassword}
            />
          </div>
        )}
        <Recaptcha setIsNotRobot={setIsNotRobot} />
        <Button
          type="submit"
          className="mt-4 h-12 w-full px-2 whitespace-normal"
          style={{
            fontSize: "clamp(0.5rem, 1.5vw, 1.25rem)",
            minWidth: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          variant="default"
          disabled={!isNotRobot} 
        >
          {loading
            ? "Creating Account..."
            : "Unlock 50% ICF Discount for a year"}
        </Button>
        <div className="mt-5 mb-5 text-base text-center text-muted-foreground">
          14-Day Free Trial with Unlimited Access.
        </div>
        <div className="mt-4 rounded-lg bg-white inline-flex justify-center items-center p-2 w-full">
          <p className="text-lg sm:text-xl font-bold">4.8</p>
          <div className="flex flex-col items-start">
            <SvgCapterra className="h-4 sm:h-6 w-min" />
            <SvgStarRating className="h-4 sm:h-5 w-min" />
          </div>
        </div>
      </div>
    </form>
  )
}

export default IcfRegistrationForm
