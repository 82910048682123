import { useState } from "react";
import { useAuthModal } from "../../auth/use-auth-modal";
import { Button } from "../../ui/button";
import { Switch } from "../../ui/switch";

const PricingToggleSquare = () => {
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const { showRegister } = useAuthModal();

  const handleToggle = () => {
    setIsToggled(() => !isToggled);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-white min-w-[366px] w-[366px] md:h-[370px] h-[346px] rounded-[16px] pricing-toggle-square-box-shadow">
      <div className="text-center mb-[12px]">
        {isToggled
          ? (
            <>
              <h3 className="font-bold text-[64px]">
                $49
                <span className="text-[24px] text-graySlate font-normal">
                  /month
                </span>
              </h3>
            </>
          )
          : (
            <>
              <h3 className="font-bold text-[64px]">
                $490
                <span className="text-[24px] text-graySlate font-normal">
                  /year
                </span>
              </h3>
            </>
          )}
      </div>
      <div className="flex items-center gap-[10px] mb-[32px]">
        <h4 className="font-bold text-[16px]">
          Annual
          <span className="text-green">&nbsp;(Save 16%)</span>
        </h4>
        <Switch defaultChecked={isToggled} onCheckedChange={handleToggle} />
        <h4 className="font-bold text-[16px]">Monthly</h4>
      </div>
      <Button
        onClick={() => showRegister({ accountType: "coach" })}
        size="lg"
      >
        Start Now
      </Button>
      <div className="mt-[20px] text-center">
        <p>Sign up for free.</p>
      </div>
    </div>
  );
};

export default PricingToggleSquare;
